/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorVariableLightModeName {
  BackgroundOverlay = "--color-backgroundOverlay-lightMode",
  CardBackground = "--color-cardBackground-lightMode",
  Dimmer = "--color-dimmer-lightMode",
  FloatingElement = "--color-floatingElement-lightMode",
  Ghost = "--color-ghost-lightMode",
  MenuPopup = "--color-menuPopup-lightMode",
  Primary = "--color-primary-lightMode",
  Red = "--color-red-lightMode",
  RedHover = "--color-redHover-lightMode",
  Secondary = "--color-secondary-lightMode",
  Shader = "--color-shader-lightMode",
  SkeletonBase = "--color-skeletonBase-lightMode",
  SkeletonHighlight = "--color-skeletonHighlight-lightMode",
  Tertiary = "--color-tertiary-lightMode",
  WebsiteBackground = "--color-websiteBackground-lightMode",
  White = "--color-white-lightMode",
}
