import { isBrowser } from "@web/utils/env/isBrowser";

// From https://stackoverflow.com/a/25490531
export function getCookieByName(name: string): string {
  if (!isBrowser()) {
    return "";
  }

  return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || "";
}
