import { notifyError } from "ui";
import { NotifyErrorDescription } from "@web/types/enums/NotifyErrorDescription";
import { firebaseLogout } from "@web/utils/firebase/auth/firebaseLogout";
import { postLogout } from "@web/utils/rest/login/postLogout";

export async function logout() {
  const result = await postLogout();
  if (result == null || !result.success) {
    notifyError(NotifyErrorDescription.UnexpectedLogoutError);
    return;
  }

  await firebaseLogout();

  window.location.href = "/login";
}
