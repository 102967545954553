import { ResponsiveContainer } from "@ui/components/containers/ResponsiveContainer";
import styles from "@ui/components/containers/css/PageBody.module.css";
import { joinClasses } from "@ui/utils/joinClasses";
import { Header2 } from "@ui/components/text/Header2";
import { ColorClass } from "@ui/types/enums/ColorClass";

type Props = {
  children: any;
  className?: string;
  hasBottomPadding?: boolean;
  hasHorizontalPadding?: boolean;
  hasTopPadding?: boolean;
  header?: JSX.Element | string;
};

export function PageBody({
  children,
  className,
  hasBottomPadding = true,
  hasHorizontalPadding = true,
  hasTopPadding = true,
  header,
}: Props): JSX.Element {
  const classNameToUse = joinClasses(
    hasBottomPadding ? styles.bottom : null,
    hasTopPadding ? styles.top : null,
    className,
    styles.headerAndChildren
  );

  const headerElem =
    typeof header === "string" ? (
      <Header2 colorClass={ColorClass.Primary} textAlign="center">
        {header}
      </Header2>
    ) : (
      header
    );

  if (hasHorizontalPadding) {
    return (
      <ResponsiveContainer className={classNameToUse}>
        {headerElem}
        <div>{children}</div>
      </ResponsiveContainer>
    );
  }

  return (
    <div className={classNameToUse}>
      {headerElem}
      <div>{children}</div>
    </div>
  );
}
