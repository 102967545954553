import styles from "@web/components/footers/css/Footer.module.css";
import { Body1, ColorClass } from "ui";

export function Footer(): JSX.Element {
  return (
    <div className={styles.footer}>
      <Body1 colorClass={ColorClass.Primary}>Footer</Body1>
    </div>
  );
}
