/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

import { ColorVariableLightModeName } from "@ui/types/enums/ColorVariableLightModeName";
import { ColorVariableName } from "@ui/types/enums/ColorVariableName";

export const COLOR_VARIABLE_NAME_TO_COLOR_VARIABLE_LIGHT_MODE_NAME: Record<
  ColorVariableName,
  ColorVariableLightModeName
> = {
  [ColorVariableName.BackgroundOverlay]:
    ColorVariableLightModeName.BackgroundOverlay,
  [ColorVariableName.CardBackground]: ColorVariableLightModeName.CardBackground,
  [ColorVariableName.Dimmer]: ColorVariableLightModeName.Dimmer,
  [ColorVariableName.FloatingElement]:
    ColorVariableLightModeName.FloatingElement,
  [ColorVariableName.Ghost]: ColorVariableLightModeName.Ghost,
  [ColorVariableName.MenuPopup]: ColorVariableLightModeName.MenuPopup,
  [ColorVariableName.Primary]: ColorVariableLightModeName.Primary,
  [ColorVariableName.Red]: ColorVariableLightModeName.Red,
  [ColorVariableName.RedHover]: ColorVariableLightModeName.RedHover,
  [ColorVariableName.Secondary]: ColorVariableLightModeName.Secondary,
  [ColorVariableName.Shader]: ColorVariableLightModeName.Shader,
  [ColorVariableName.SkeletonBase]: ColorVariableLightModeName.SkeletonBase,
  [ColorVariableName.SkeletonHighlight]:
    ColorVariableLightModeName.SkeletonHighlight,
  [ColorVariableName.Tertiary]: ColorVariableLightModeName.Tertiary,
  [ColorVariableName.WebsiteBackground]:
    ColorVariableLightModeName.WebsiteBackground,
  [ColorVariableName.White]: ColorVariableLightModeName.White,
};
