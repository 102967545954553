/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum ColorVariableDarkModeName {
  BackgroundOverlay = "--color-backgroundOverlay-darkMode",
  CardBackground = "--color-cardBackground-darkMode",
  Dimmer = "--color-dimmer-darkMode",
  FloatingElement = "--color-floatingElement-darkMode",
  Ghost = "--color-ghost-darkMode",
  MenuPopup = "--color-menuPopup-darkMode",
  Primary = "--color-primary-darkMode",
  Red = "--color-red-darkMode",
  RedHover = "--color-redHover-darkMode",
  Secondary = "--color-secondary-darkMode",
  Shader = "--color-shader-darkMode",
  SkeletonBase = "--color-skeletonBase-darkMode",
  SkeletonHighlight = "--color-skeletonHighlight-darkMode",
  Tertiary = "--color-tertiary-darkMode",
  WebsiteBackground = "--color-websiteBackground-darkMode",
  White = "--color-white-darkMode",
}
