/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

export enum BackgroundColorClass {
  BackgroundOverlay = "backgroundColorBackgroundOverlay",
  CardBackground = "backgroundColorCardBackground",
  Dimmer = "backgroundColorDimmer",
  FloatingElement = "backgroundColorFloatingElement",
  Ghost = "backgroundColorGhost",
  MenuPopup = "backgroundColorMenuPopup",
  Primary = "backgroundColorPrimary",
  Red = "backgroundColorRed",
  RedHover = "backgroundColorRedHover",
  Secondary = "backgroundColorSecondary",
  Shader = "backgroundColorShader",
  SkeletonBase = "backgroundColorSkeletonBase",
  SkeletonHighlight = "backgroundColorSkeletonHighlight",
  Tertiary = "backgroundColorTertiary",
  WebsiteBackground = "backgroundColorWebsiteBackground",
  White = "backgroundColorWhite",
}
