import { Drawer } from "antd";
import { CloseButton } from "@ui/components/buttons/CloseButton";
import { ResponsiveContainer } from "@ui/components/containers/ResponsiveContainer";
import { NavLink } from "@ui/components/text/NavLink";
import styles from "@ui/components/drawers/css/BottomDrawer.module.css";
import { useWindowDimensions } from "@ui/hooks/dimensions/useWindowDimensions";
import { useEffect, useState } from "react";
import { ColorClass } from "@ui/types/enums/ColorClass";
import { ColorValue } from "@ui/types/enums/ColorValue";
import { ElementId } from "@ui/types/enums/ElementId";
import { Body1 } from "@ui/components/text/Body1";

// NOTE: Determined experimentally. May change if the title row uses a different font.
const DEFAULT_TITLE_ROW_HEIGHT = 40;
// NOTE: Obtained from the global .ant-drawer-body CSS class
const DRAWER_TOP_PADDING = 24;
// The height of the bottom drawer will never exceed the window height - HEIGHT_BUFFER.
// Without this, the bottom drawer could take up the entire screen, which looks weird.
const HEIGHT_BUFFER = 40;
// Height of the line that separates the title row from the drawer body.
const SEPARATOR_HEIGHT = 1;

type Props = {
  bottomDrawerHeight?: number;
  children: any;
  description?: string | JSX.Element;
  disableResponsiveContainer?: boolean;
  isShown: boolean;
  onHide: () => void;
  title?: string | JSX.Element;
};

/**
 * NOTE: should typically not be used directly—prefer Modal instead, which converts to BottomDrawer
 * for small screen widths.
 */
export function BottomDrawer({
  bottomDrawerHeight,
  children,
  description,
  disableResponsiveContainer = false,
  isShown,
  onHide,
  title = "",
}: Props): JSX.Element {
  const [bodyScrollHeight, setBodyScrollHeight] = useState(0);
  const { width, height: windowHeight } = useWindowDimensions();
  const [titleRowHeight, setTitleRowHeight] = useState(0);
  useEffect(() => {
    if (isShown) {
      // Use setTimeout so the element is guaranteed to be found (but also have a default value
      // just in case)
      setTimeout(() => {
        setTitleRowHeight(
          document.getElementById(ElementId.BottomDrawerTitleRow)
            ?.offsetHeight ?? DEFAULT_TITLE_ROW_HEIGHT
        );
      }, 0);
    }
  }, [isShown, width]);

  const titleAreaHeight =
    titleRowHeight + DRAWER_TOP_PADDING + SEPARATOR_HEIGHT;

  const drawerHeight =
    bottomDrawerHeight ??
    // By default, the height is dynamic. If possible, the entire body is shown. Otherwise,
    // the drawer extends up to some max height, and then the body becomes scrollable.
    Math.min(windowHeight - HEIGHT_BUFFER, bodyScrollHeight + titleAreaHeight);

  return (
    <Drawer
      closable={false}
      height={drawerHeight}
      maskStyle={{ backgroundColor: ColorValue.BackgroundOverlay }}
      open={isShown}
      placement="bottom"
      onClose={onHide}
    >
      <ResponsiveContainer>
        <div className={styles.titleRow} id={ElementId.BottomDrawerTitleRow}>
          <CloseButton colorValue={ColorValue.Secondary} onClick={onHide} />
          <NavLink colorClass={ColorClass.Primary}>{title}</NavLink>
          <CloseButton colorValue={ColorValue.Secondary} isShown={false} />
        </div>
      </ResponsiveContainer>
      <div className={styles.separator} />
      {/* Restrict height so body is scrollable, and not the entire drawer. */}
      <div
        className={styles.body}
        ref={(elem) => {
          if (elem != null) {
            setBodyScrollHeight(elem.scrollHeight);

            // In case children contains some images which take a bit to load
            setTimeout(() => setBodyScrollHeight(elem.scrollHeight), 0);
            setTimeout(() => setBodyScrollHeight(elem.scrollHeight), 100);
            setTimeout(() => setBodyScrollHeight(elem.scrollHeight), 1000);
          }
        }}
        // Set height so body overflows, and not entire drawer
        style={{
          maxHeight: drawerHeight - titleAreaHeight,
        }}
      >
        {description != null &&
          (typeof description === "string" ? (
            <Body1
              className={styles.description}
              colorClass={ColorClass.Primary}
              textAlign="center"
            >
              {description}
            </Body1>
          ) : (
            description
          ))}
        {disableResponsiveContainer ? (
          children
        ) : (
          <ResponsiveContainer>{children}</ResponsiveContainer>
        )}
      </div>
    </Drawer>
  );
}
